import React from 'react'
import Helmet from 'react-helmet'

import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Img from 'gatsby-image'

import Layout from 'src/components/layout'
import Row from 'src/components/row'
import CTA from 'src/components/cta'

export default ({ data }) => (
  <Layout>
    <div className="content">
      <Helmet>
        <title>
          Backflow Prevention - {data.site.siteMetadata.title}
        </title>
      </Helmet>

      <div>
        <h1>What is an initial Cross Connection Survey?</h1>
        <p>It is much like a land survey, except it captures water connections in a facility.</p>
        <p>
          On April 1, 2017, <a href="https://www.peelregion.ca/council/bylaws/2010s/2017/bl-10-2017.pdf">
            Peel introduced a back flow bylaw
          </a>. Essentially, building owners are required to test their backflow
          prevention devices.  Also, building owners are required to submit a
          Cross Connection survey essentially listing information about the
          current building piping.  This helps determine the requirements for
          compliance and current building status.
        </p>

        <p>The Region of Peel defines Cross connections and backflow devices as follows:</p>
        <blockquote>
          <p>Backflow is the flow of water from a high to a low-pressure area. It happens when there is a drop in pressure in the water distribution system.</p>
          <p>Cross-connections are in every drinking water supply system. Depending on the system’s size, many potential cross-connections may exist. When backflow happens through an unprotected cross-connection, it could threaten the integrity of the water system.</p>
        </blockquote>

        <p>All municipalities are working on safeguarding their drinking water by requiring that private plumbing systems with cross-connections also have backflow prevention devices.  By-laws such as Backflow prevention By-law 10-2017 reinforce this.</p>
        <p></p>
        <p>The information collected on the survey will determine whether or not there is acceptable protection on the lines or if prevention devices need to be installed.</p>
        <p>In Peel, Backflow prevention devices must be installed within one year of your survey being completed. Your backflow device must also be tested every year.</p>
        <p>
          <strong><u>ARC Plumbing has already Completed the Application of Qualified Person Registry.  We are registered with the Region of Peel and other areas in the GTA and beyond</u></strong>
        </p>

      </div>
    </div>
    <CTA />
    <div className="content">
      <div>


        <ul>
          <li>We are qualified, certified and registered to test, install, service and/or repair Backflow preventers.</li>
          <li>We are able to complete and submit all paperwork for backflow preventer testing in various municipalities.</li>
          <li>We are able to complete and submit all paperwork for the purposes of completing a cross connection survey at your facility</li>
          <li>We have OWWA (Ontario Waterworks Association) certified testers </li>
          <li>ARC Plumbing has already Completed the Application of Qualified Person Registry.  We are registered with the Region of Peel and other areas in the GTA and beyond</li>
          <li>Authorized to carry work out in your municipality – City of Toronto, Region of Peel, Halton Region, London, etc.</li>
        </ul>

        <p>
          Contact us to book an Initial Cross Connection Survey or call immediately for more information.
        </p>

      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query BackflowPreventionQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
